import { render, staticRenderFns } from "./FinalAllocatedList.vue?vue&type=template&id=5c7b985c&scoped=true&"
import script from "./FinalAllocatedList.vue?vue&type=script&lang=js&"
export * from "./FinalAllocatedList.vue?vue&type=script&lang=js&"
import style0 from "./FinalAllocatedList.vue?vue&type=style&index=0&id=5c7b985c&prod&scoped=true&lang=css&"
import style1 from "./FinalAllocatedList.vue?vue&type=style&index=1&id=5c7b985c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c7b985c",
  null
  
)

export default component.exports